export const InternationRoutes = [
{
    from: 'Lahore', 
    to: 'London',
    departCode: 'LHE',
    ArrivalCode: 'LHR',
    pathName: 'cheap-flights-from-lahore-to-london',
    Heading: 'Discover Affordable Flights to London',
    backGroundImg: require('../../assets/city/banners/international pages/london.jpeg')
},
{
    from: 'Lahore', 
    to: 'Dubai',
    departCode: 'LHE',
    ArrivalCode: 'DXB',
    pathName: 'cheap-flights-from-lahore-to-dubai',
    Heading: 'Book Lahore to Dubai Flights',
    backGroundImg: require('../../assets/city/banners/international pages/Dubai.png')
},
{
    from: 'Lahore', 
    to: 'Toronto',
    departCode: 'LHE',
    ArrivalCode: 'YTZ',
    pathName: 'cheap-flights-from-lahore-to-toronto',
    Heading: 'Cheap Flights from Lahore to Toronto',
    backGroundImg: require('../../assets/city/banners/international pages/Toronto.png')
},
{
    from: 'Lahore', 
    to: 'NewYork',
    departCode: 'LHE',
    ArrivalCode: 'JFK',
    pathName: 'cheap-flights-from-lahore-to-newyork',
    Heading: 'Find Affordable Flights to New York',
    backGroundImg: require('../../assets/city/banners/international pages/newYork.png')
},
{
    from: 'Lahore', 
    to: 'Boston',
    departCode: 'LHE',
    ArrivalCode: 'BOS',
    pathName: 'lahore-to-boston',
    // pathName: 'cheap-flights-from-lahore-to-boston',
    Heading: 'Lahore to Boston Flight Deals',
    backGroundImg: require('../../assets/city/banners/international pages/boston.png')
},
{
    from: 'Lahore', 
    to: 'Perth',
    departCode: 'LHE',
    ArrivalCode: 'PER',
    pathName: 'lahore-to-perth',
    // pathName: 'cheap-flights-from-lahore-to-perth',
    Heading: 'Explore Lahore to Perth Flights',
    backGroundImg: require('../../assets/city/banners/international pages/perth.png')
},
{
    from: 'Lahore', 
    to: 'Manchester',
    departCode: 'LHE',
    ArrivalCode: 'MAN',
    pathName: 'cheap-flights-from-lahore-to-manchester',
    Heading: 'Affordable Flights to Manchester from Lahore',
    backGroundImg: require('../../assets/city/banners/international pages/Manchester.png')
},
{
    from: 'Lahore', 
    to: 'Melbourne',
    departCode: 'LHE',
    ArrivalCode: 'AVV',
    pathName: 'cheap-flights-from-lahore-to-melbourne',
    Heading: 'Book Cheap Flights to Melbourne',
    backGroundImg: require('../../assets/city/banners/international pages/Malbourne.png')
},
{
    from: 'Lahore', 
    to: 'Atlanta',
    departCode: 'LHE',
    ArrivalCode: 'ATL',
    pathName: 'cheap-flights-from-lahore-to-atlanta',
    Heading: 'Discover Lahore to Atlanta Deals',
    backGroundImg: require('../../assets/city/banners/international pages/Atlanta.png')
},
{
    from: 'Lahore', 
    to: 'Doha',
    departCode: 'LHE',
    ArrivalCode: 'DOH',
    pathName: 'cheap-flights-from-lahore-to-doha',
    Heading: 'Lahore to Doha Flights Today',
    backGroundImg: require('../../assets/city/banners/international pages/Doha.png')
},
{
    from: 'Lahore', 
    to: 'Sydney',
    departCode: 'LHE',
    ArrivalCode: 'SYD',
    pathName: 'cheap-flights-from-lahore-to-sydney',
    Heading: 'Find Lahore to Sydney Flights',
    backGroundImg: require('../../assets/city/banners/international pages/Sydney.png')
},
{
    from: 'Peshawar',
    to: 'London',
    departCode: 'PEW',
    ArrivalCode: 'LHR',
    pathName: 'cheap-flights-from-peshawar-to-london',
    Heading: 'Discover Affordable Flights to London',
    backGroundImg: require('../../assets/city/banners/international pages/london.jpeg')
},
{
    from: 'Peshawar',
    to: 'Dubai',
    departCode: 'PEW',
    ArrivalCode: 'DXB',
    pathName: 'cheap-flights-from-peshawar-to-dubai',
    Heading: 'Book Peshawar to Dubai Flights Today',
    backGroundImg: require('../../assets/city/banners/international pages/Dubai.png')
},
{
    from: 'Peshawar',
    to: 'Toronto',
    departCode: 'PEW',
    ArrivalCode: 'YTZ',
    pathName: 'cheap-flights-from-peshawar-to-toronto',
    Heading: 'Cheap Flights from Peshawar to Toronto Available',
    backGroundImg: require('../../assets/city/banners/international pages/Toronto.png')
},
{
    from: 'Peshawar',
    to: 'NewYork',
    departCode: 'PEW',
    ArrivalCode: 'JFK',
    pathName: 'cheap-flights-from-peshawar-to-newyork',
    Heading: 'Find Affordable Flights to New York',
    backGroundImg: require('../../assets/city/banners/international pages/newYork.png')
},
{
    from: 'Peshawar',
    to: 'Boston',
    departCode: 'PEW',
    ArrivalCode: 'BOS',
    pathName: 'cheap-flights-from-peshawar-to-boston',
    Heading: 'Get Peshawar to Boston Flight Deals',
    backGroundImg: require('../../assets/city/banners/international pages/boston.png')
},
{
    from: 'Peshawar',
    to: 'Perth',
    departCode: 'PEW',
    ArrivalCode: 'PER',
    pathName: 'cheap-flights-from-peshawar-to-perth',
    Heading: 'Explore Peshawar to Perth Flights Now',
    backGroundImg: require('../../assets/city/banners/international pages/perth.png')
},
{
    from: 'Peshawar',
    to: 'Manchester',
    departCode: 'PEW',
    ArrivalCode: 'MAN',
    pathName: 'cheap-flights-from-peshawar-to-manchester',
    Heading: 'Affordable Flights to Manchester from Peshawar',
    backGroundImg: require('../../assets/city/banners/international pages/Manchester.png')
},
{
    from: 'Peshawar',
    to: 'Melbourne',
    departCode: 'PEW',
    ArrivalCode: 'AVV',
    pathName: 'cheap-flights-from-peshawar-to-melbourne',
    Heading: 'Book Cheap Flights to Melbourne',
    backGroundImg: require('../../assets/city/banners/international pages/Malbourne.png')
},
{
    from: 'Peshawar',
    to: 'Atlanta',
    departCode: 'PEW',
    ArrivalCode: 'ATL',
    pathName: 'cheap-flights-from-peshawar-to-atlanta',
    Heading: 'Discover Peshawar to Atlanta Flight Deals',
    backGroundImg: require('../../assets/city/banners/international pages/Atlanta.png')
},
{
    from: 'Peshawar',
    to: 'Doha',
    departCode: 'PEW',
    ArrivalCode: 'DOH',
    pathName: 'cheap-flights-from-peshawar-to-doha',
    Heading: 'Peshawar to Doha Flights Available Today',
    backGroundImg: require('../../assets/city/banners/international pages/Doha.png')
},
{
    from: 'Peshawar',
    to: 'LosAngeles',
    departCode: 'PEW',
    ArrivalCode: 'CCD',
    pathName: 'cheap-flights-from-peshawar-to-losangeles',
    Heading: 'Find Peshawar to Los Angeles Flights',
    backGroundImg: require('../../assets/city/banners/international pages/losAngels.png')
},
{
    from: 'Peshawar',
    to: 'Jeddah',
    departCode: 'PEW',
    ArrivalCode: 'JED',
    pathName: 'cheap-flights-from-peshawar-to-jeddah',
    Heading: 'Cheap Peshawar to Jeddah Flights Today',
    backGroundImg: require('../../assets/city/banners/international pages/Jeddah.png')
},
{
    from: 'Peshawar',
    to: 'Istanbul',
    departCode: 'PEW',
    ArrivalCode: 'IST',
    pathName: 'cheap-flights-from-peshawar-to-istanbul',
    Heading: 'Affordable Peshawar to Istanbul Travel Now',
    backGroundImg: require('../../assets/city/banners/international pages/istanbul.png')
},
{
    from: 'Faisalabad',
    to: 'LosAngeles',
    departCode: 'LYP',
    ArrivalCode: 'CCD',
    pathName: 'cheap-flights-from-faisalabad-to-losangeles',
    Heading: 'Find Faisalabad to Los Angeles Flights',
    backGroundImg: require('../../assets/city/banners/international pages/losAngels.png')
},
{
    from: 'Faisalabad',
    to: 'Jeddah',
    departCode: 'LYP',
    ArrivalCode: 'JED',
    pathName: 'cheap-flights-from-faisalabad-to-jeddah',
    Heading: 'Cheap Faisalabad to Jeddah Flights Today',
    backGroundImg: require('../../assets/city/banners/international pages/Jeddah.png')
},
{
    from: 'Faisalabad',
    to: 'Istanbul',
    departCode: 'LYP',
    ArrivalCode: 'IST',
    pathName: 'cheap-flights-from-faisalabad-to-istanbul',
    Heading: 'Affordable Faisalabad to Istanbul Travel Now',
    backGroundImg: require('../../assets/city/banners/international pages/istanbul.png')
},{
    from: 'Karachi',
    to: 'London',
    departCode: 'KHI',
    ArrivalCode: 'LHR',
    pathName: 'cheap-flights-from-karachi-to-london',
    Heading: 'Discover Affordable Flights to London',
    backGroundImg: require('../../assets/city/banners/international pages/London.png')
},
{
    from: 'Karachi',
    to: 'Dubai',
    departCode: 'KHI',
    ArrivalCode: 'DXB',
    pathName: 'cheap-flights-from-karachi-to-dubai',
    Heading: 'Book Karachi to Dubai Flights Today',
    backGroundImg: require('../../assets/city/banners/international pages/Dubai.png')
},
{
    from: 'Karachi',
    to: 'Toronto',
    departCode: 'KHI',
    ArrivalCode: 'YTZ',
    pathName: 'cheap-flights-from-karachi-to-toronto',
    Heading: 'Cheap Flights from Karachi to Toronto',
    backGroundImg: require('../../assets/city/banners/international pages/Toronto.png')
},
{
    from: 'Karachi',
    to: 'NewYork',
    departCode: 'KHI',
    ArrivalCode: 'JFK',
    pathName: 'cheap-flights-from-karachi-to-newyork',
    Heading: 'Find Affordable Flights to New York',
    backGroundImg: require('../../assets/city/banners/international pages/newYork.png')
},
{
    from: 'Karachi',
    to: 'Boston',
    departCode: 'KHI',
    ArrivalCode: 'BOS',
    pathName: 'cheap-flights-from-karachi-to-boston',
    Heading: 'Get Karachi to Boston Flight Deals',
    backGroundImg: require('../../assets/city/banners/international pages/boston.png')
},
{
    from: 'Karachi',
    to: 'Perth',
    departCode: 'KHI',
    ArrivalCode: 'PER',
    pathName: 'cheap-flights-from-karachi-to-perth',
    Heading: 'Explore Karachi to Perth Flights Now',
    backGroundImg: require('../../assets/city/banners/international pages/perth.png')
},
{
    from: 'Karachi',
    to: 'Manchester',
    departCode: 'KHI',
    ArrivalCode: 'MAN',
    pathName: 'cheap-flights-from-karachi-to-manchester',
    Heading: 'Affordable Flights to Manchester from Karachi',
    backGroundImg: require('../../assets/city/banners/international pages/Manchester.png')
},
{
    from: 'Karachi',
    to: 'Melbourne',
    departCode: 'KHI',
    ArrivalCode: 'AVV',
    pathName: 'cheap-flights-from-karachi-to-melbourne',
    Heading: 'Book Cheap Flights to Melbourne',
    backGroundImg: require('../../assets/city/banners/international pages/Malbourne.png')
},
{
    from: 'Karachi',
    to: 'Atlanta',
    departCode: 'KHI',
    ArrivalCode: 'ATL',
    pathName: 'cheap-flights-from-karachi-to-atlanta',
    Heading: 'Discover Karachi to Atlanta Flight Deals',
    backGroundImg: require('../../assets/city/banners/international pages/Atlanta.png')
},
{
    from: 'Karachi',
    to: 'Doha',
    departCode: 'KHI',
    ArrivalCode: 'DOH',
    pathName: 'cheap-flights-from-karachi-to-doha',
    Heading: 'Karachi to Doha Flights Available Today',
    backGroundImg: require('../../assets/city/banners/international pages/Doha.png')
},
{
    from: 'Karachi',
    to: 'LosAngeles',
    departCode: 'KHI',
    ArrivalCode: 'CCD',
    pathName: 'cheap-flights-from-karachi-to-losangeles',
    Heading: 'Find Karachi to Los Angeles Flights',
    backGroundImg: require('../../assets/city/banners/international pages/losAngels.png')
},
{
    from: 'Karachi',
    to: 'Jeddah',
    departCode: 'KHI',
    ArrivalCode: 'JED',
    pathName: 'cheap-flights-from-karachi-to-jeddah',
    Heading: 'Cheap Karachi to Jeddah Flights Today',
    backGroundImg: require('../../assets/city/banners/international pages/Jeddah.png')
},
{
    from: 'Karachi',
    to: 'Istanbul',
    departCode: 'KHI',
    ArrivalCode: 'IST',
    pathName: 'cheap-flights-from-karachi-to-istanbul',
    Heading: 'Affordable Karachi to Istanbul Travel Now',
    backGroundImg: require('../../assets/city/banners/international pages/istanbul.png')
},
{
    from: 'Quetta',
    to: 'London',
    departCode: 'UET',
    ArrivalCode: 'LHR',
    pathName: 'cheap-flights-from-quetta-to-london',
    Heading: 'well come to london',
    backGroundImg: require('../../assets/city/banners/international pages/London.png')
},
{
    from: 'Quetta',
    to: 'Dubai',
    departCode: 'UET',
    ArrivalCode: 'DXB',
    pathName: 'cheap-flights-from-quetta-to-dubai',
    Heading: 'Welcome to Dubai',
    backGroundImg: require('../../assets/city/banners/international pages/Dubai.png')
},
{
    from: 'Quetta',
    to: 'Toronto',
    departCode: 'UET',
    ArrivalCode: 'YTZ',
    pathName: 'cheap-flights-from-quetta-to-toronto',
    Heading: 'Welcome to Toronto',
    backGroundImg: require('../../assets/city/banners/international pages/Toronto.png')
},
{
    from: 'Quetta',
    to: 'NewYork',
    departCode: 'UET',
    ArrivalCode: 'JFK',
    pathName: 'cheap-flights-from-quetta-to-newyork',
    Heading: 'Welcome to New York',
    backGroundImg: require('../../assets/city/banners/international pages/newYork.png')
},
{
    from: 'Quetta',
    to: 'Boston',
    departCode: 'UET',
    ArrivalCode: 'BOS',
    pathName: 'cheap-flights-from-quetta-to-boston',
    Heading: 'Welcome to Boston',
    backGroundImg: require('../../assets/city/banners/international pages/boston.png')
},
{
    from: 'Quetta',
    to: 'Perth',
    departCode: 'UET',
    ArrivalCode: 'PER',
    pathName: 'cheap-flights-from-quetta-to-perth',
    Heading: 'Welcome to Perth',
    backGroundImg: require('../../assets/city/banners/international pages/perth.png')
},
{
    from: 'Quetta',
    to: 'Manchester',
    departCode: 'UET',
    ArrivalCode: 'MAN',
    pathName: 'cheap-flights-from-quetta-to-manchester',
    Heading: 'Welcome to Manchester',
    backGroundImg: require('../../assets/city/banners/international pages/Manchester.png')
},
{
    from: 'Quetta',
    to: 'Melbourne',
    departCode: 'UET',
    ArrivalCode: 'AVV',
    pathName: 'cheap-flights-from-quetta-to-melbourne',
    Heading: 'Welcome to Melbourne',
    backGroundImg: require('../../assets/city/banners/international pages/Malbourne.png')
},
{
    from: 'Quetta',
    to: 'Atlanta',
    departCode: 'UET',
    ArrivalCode: 'ATL',
    pathName: 'cheap-flights-from-quetta-to-atlanta',
    Heading: 'Welcome to Atlanta',
    backGroundImg: require('../../assets/city/banners/international pages/Atlanta.png')
},
{
    from: 'Quetta',
    to: 'Doha',
    departCode: 'UET',
    ArrivalCode: 'DOH',
    pathName: 'cheap-flights-from-quetta-to-doha',
    Heading: 'Welcome to Doha',
    backGroundImg: require('../../assets/city/banners/international pages/Doha.png')
},
{
    from: 'Quetta',
    to: 'LosAngeles',
    departCode: 'UET',
    ArrivalCode: 'LAX',
    pathName: 'cheap-flights-from-quetta-to-losangeles',
    Heading: 'Welcome to Los Angeles',
    backGroundImg: require('../../assets/city/banners/international pages/losAngels.png')
},
{
    from: 'Quetta',
    to: 'Jeddah',
    departCode: 'UET',
    ArrivalCode: 'JED',
    pathName: 'cheap-flights-from-quetta-to-jeddah',
    Heading: 'Welcome to Jeddah',
    backGroundImg: require('../../assets/city/banners/international pages/Jeddah.png')
},
{
    from: 'Quetta',
    to: 'Istanbul',
    departCode: 'UET',
    ArrivalCode: 'IST',
    pathName: 'cheap-flights-from-quetta-to-istanbul',
    Heading: 'Welcome to Istanbul',
    backGroundImg: require('../../assets/city/banners/international pages/istanbul.png')
},{
    from: 'Islamabad',
    to: 'London',
    departCode: 'ISB',
    ArrivalCode: 'LHR',
    pathName: 'cheap-flights-from-islamabad-to-london',
    Heading: 'Welcome to London',
    backGroundImg: require('../../assets/city/banners/international pages/London.png')
},
{
    from: 'Islamabad',
    to: 'Dubai',
    departCode: 'ISB',
    ArrivalCode: 'DXB',
    pathName: 'cheap-flights-from-islamabad-to-dubai',
    Heading: 'Welcome to Dubai',
    backGroundImg: require('../../assets/city/banners/international pages/Dubai.png')
},
{
    from: 'Islamabad',
    to: 'Toronto',
    departCode: 'ISB',
    ArrivalCode: 'YTZ',
    pathName: 'cheap-flights-from-islamabad-to-toronto',
    Heading: 'Welcome to Toronto',
    backGroundImg: require('../../assets/city/banners/international pages/Toronto.png')
},
{
    from: 'Islamabad',
    to: 'NewYork',
    departCode: 'ISB',
    ArrivalCode: 'JFK',
    pathName: 'cheap-flights-from-islamabad-to-newyork',
    Heading: 'Welcome to New York',
    backGroundImg: require('../../assets/city/banners/international pages/newYork.png')
},
{
    from: 'Islamabad',
    to: 'Boston',
    departCode: 'ISB',
    ArrivalCode: 'BOS',
    pathName: 'cheap-flights-from-islamabad-to-boston',
    Heading: 'Welcome to Boston',
    backGroundImg: require('../../assets/city/banners/international pages/boston.png')
},
{
    from: 'Islamabad',
    to: 'Perth',
    departCode: 'ISB',
    ArrivalCode: 'PER',
    pathName: 'cheap-flights-from-islamabad-to-perth',
    Heading: 'Welcome to Perth',
    backGroundImg: require('../../assets/city/banners/international pages/perth.png')
},
{
    from: 'Islamabad',
    to: 'Manchester',
    departCode: 'ISB',
    ArrivalCode: 'MAN',
    pathName: 'cheap-flights-from-islamabad-to-manchester',
    Heading: 'Welcome to Manchester',
    backGroundImg: require('../../assets/city/banners/international pages/Manchester.png')
},
{
    from: 'Islamabad',
    to: 'Melbourne',
    departCode: 'ISB',
    ArrivalCode: 'AVV',
    pathName: 'cheap-flights-from-islamabad-to-melbourne',
    Heading: 'Welcome to Melbourne',
    backGroundImg: require('../../assets/city/banners/international pages/Malbourne.png')
},
{
    from: 'Islamabad',
    to: 'Atlanta',
    departCode: 'ISB',
    ArrivalCode: 'ATL',
    pathName: 'cheap-flights-from-islamabad-to-atlanta',
    Heading: 'Welcome to Atlanta',
    backGroundImg: require('../../assets/city/banners/international pages/Atlanta.png')
},
{
    from: 'Islamabad',
    to: 'Doha',
    departCode: 'ISB',
    ArrivalCode: 'DOH',
    pathName: 'cheap-flights-from-islamabad-to-doha',
    Heading: 'Welcome to Doha',
    backGroundImg: require('../../assets/city/banners/international pages/Doha.png')
},
{
    from: 'Islamabad',
    to: 'LosAngeles',
    departCode: 'ISB',
    ArrivalCode: 'LAX',
    pathName: 'cheap-flights-from-islamabad-to-losangeles',
    Heading: 'Welcome to Los Angeles',
    backGroundImg: require('../../assets/city/banners/international pages/losAngels.png')
},
{
    from: 'Islamabad',
    to: 'Jeddah',
    departCode: 'ISB',
    ArrivalCode: 'JED',
    pathName: 'cheap-flights-from-islamabad-to-jeddah',
    Heading: 'Welcome to Jeddah',
    backGroundImg: require('../../assets/city/banners/international pages/Jeddah.png')
},
{
    from: 'Islamabad',
    to: 'Istanbul',
    departCode: 'ISB',
    ArrivalCode: 'IST',
    pathName: 'cheap-flights-from-islamabad-to-istanbul',
    Heading: 'Welcome to Istanbul',
    backGroundImg: require('../../assets/city/banners/international pages/istanbul.png')
},
{
    from: 'Multan',
    to: 'London',
    departCode: 'MUX',
    ArrivalCode: 'LHR',
    pathName: 'cheap-flights-from-multan-to-london',
    Heading: 'Welcome to London',
    backGroundImg: require('../../assets/city/banners/international pages/London.png')
},
{
    from: 'Multan',
    to: 'Dubai',
    departCode: 'MUX',
    ArrivalCode: 'DXB',
    pathName: 'cheap-flights-from-multan-to-dubai',
    Heading: 'Welcome to Dubai',
    backGroundImg: require('../../assets/city/banners/international pages/Dubai.png')
},
{
    from: 'Multan',
    to: 'Toronto',
    departCode: 'MUX',
    ArrivalCode: 'YTZ',
    pathName: 'cheap-flights-from-multan-to-toronto',
    Heading: 'Welcome to Toronto',
    backGroundImg: require('../../assets/city/banners/international pages/Toronto.png')
},
{
    from: 'Multan',
    to: 'NewYork',
    departCode: 'MUX',
    ArrivalCode: 'JFK',
    pathName: 'cheap-flights-from-multan-to-newyork',
    Heading: 'Welcome to New York',
    backGroundImg: require('../../assets/city/banners/international pages/newYork.png')
},
{
    from: 'Multan',
    to: 'Boston',
    departCode: 'MUX',
    ArrivalCode: 'BOS',
    pathName: 'cheap-flights-from-multan-to-boston',
    Heading: 'Welcome to Boston',
    backGroundImg: require('../../assets/city/banners/international pages/boston.png')
},
{
    from: 'Multan',
    to: 'Perth',
    departCode: 'MUX',
    ArrivalCode: 'PER',
    pathName: 'cheap-flights-from-multan-to-perth',
    Heading: 'Welcome to Perth',
    backGroundImg: require('../../assets/city/banners/international pages/perth.png')
},
{
    from: 'Multan',
    to: 'Manchester',
    departCode: 'MUX',
    ArrivalCode: 'MAN',
    pathName: 'cheap-flights-from-multan-to-manchester',
    Heading: 'Welcome to Manchester',
    backGroundImg: require('../../assets/city/banners/international pages/Manchester.png')
},
{
    from: 'Multan',
    to: 'Melbourne',
    departCode: 'MUX',
    ArrivalCode: 'AVV',
    pathName: 'cheap-flights-from-multan-to-melbourne',
    Heading: 'Welcome to Melbourne',
    backGroundImg: require('../../assets/city/banners/international pages/Malbourne.png')
},
{
    from: 'Multan',
    to: 'Atlanta',
    departCode: 'MUX',
    ArrivalCode: 'ATL',
    pathName: 'cheap-flights-from-multan-to-atlanta',
    Heading: 'Welcome to Atlanta',
    backGroundImg: require('../../assets/city/banners/international pages/Atlanta.png')
},
{
    from: 'Multan',
    to: 'Doha',
    departCode: 'MUX',
    ArrivalCode: 'DOH',
    pathName: 'cheap-flights-from-multan-to-doha',
    Heading: 'Welcome to Doha',
    backGroundImg: require('../../assets/city/banners/international pages/Doha.png')
},
{
    from: 'Multan',
    to: 'LosAngeles',
    departCode: 'MUX',
    ArrivalCode: 'LAX',
    pathName: 'cheap-flights-from-multan-to-losangeles',
    Heading: 'Welcome to Los Angeles',
    backGroundImg: require('../../assets/city/banners/international pages/losAngels.png')
},
{
    from: 'Multan',
    to: 'Jeddah',
    departCode: 'MUX',
    ArrivalCode: 'JED',
    pathName: 'cheap-flights-from-multan-to-jeddah',
    Heading: 'Welcome to Jeddah',
    backGroundImg: require('../../assets/city/banners/international pages/Jeddah.png')
},
{
    from: 'Multan',
    to: 'Istanbul',
    departCode: 'MUX',
    ArrivalCode: 'IST',
    pathName: 'cheap-flights-from-multan-to-istanbul',
    Heading: 'Welcome to Istanbul',
    backGroundImg: require('../../assets/city/banners/international pages/istanbul.png')
}
    ];